// netlify build's deploy context. can be "production", "deploy-preview" or "branch-deploy".
// if it's a preview env (including development), we'll also show Contentful entries in "Draft" status
export const IS_NETLIFY_PREVIEW = process.env.CONTEXT !== "production" || false;

// Values can be found here:
// https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
export const IS_NETLIFY_DEV_SERVER = process.env.CONTEXT === "dev";

const LOCAL_TEST = process.env.LOCAL_TEST;
export const ACTIVE_ENV = LOCAL_TEST
  ? "development"
  : process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

export const IS_GATSBY_CLOUD = process.env.GATSBY_SERVER_ENV === "dev" || false;

export const IS_DEV_ENV =
  process.env.GATSBY_ACTIVE_ENV === "development" ||
  process.env.NODE_ENV === "development";

export const LOCALE = process.env.GATSBY_LOCALE || "en-US";

export const ALGOLIA_INDEX = process.env.ALGOLIA_INDEX;

export const GATSBY_RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY!;

export const GATSBY_SENTRY_ENV = process.env.GATSBY_SENTRY_ENV!;

export const GATSBY_SENTRY_DEV_DSN = process.env.GATSBY_SENTRY_DEV_DSN!;

export const GATSBY_SENTRY_PROD_DSN = process.env.GATSBY_SENTRY_PROD_DSN!;

export const GATSBY_DATADOG_APPLICATION_ID =
  process.env.GATSBY_DATADOG_APPLICATION_ID!;

export const GATSBY_DATADOG_CLIENT_TOKEN =
  process.env.GATSBY_DATADOG_CLIENT_TOKEN!;

export const NODE_ENV = process.env.NODE_ENV;

export const GTM_CONTAINER_ID = process.env.GATSBY_GTM_CONTAINER_ID!;

export const AMPLITUDE_API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY!;

export const GATSBY_SERVER_ENV = process.env.GATSBY_SERVER_ENV!;

export const IS_PROD = GATSBY_SERVER_ENV === "prod";

export const LOCALE_TO_DOMAIN: Record<string, string> = {
  "en-US": "https://www.flexport.com",
  "zh-CN": "https://cn.flexport.com",
};

/**
 * sanitize-html configuration.
 */
export const SANITIZATION_OPTIONS = {
  allowedTags: [
    "a",
    "blockquote",
    "br",
    "div",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "iframe",
    "img",
    "li",
    "ol",
    "p",
    "section",
    "span",
    "table",
    "tbody",
    "td",
    "th",
    "thead",
    "tr",
    "ul",
  ],
  alwaysAllowedAttributes: ["class", "target"],
  allowedAttributesPerTag: {
    img: ["src", "alt"],
    iframe: ["src", "width", "height", "style"],
    a: ["data-*", "href", "rel"],
  },
  get allowedAttributes() {
    return this.allowedTags.reduce(
      (obj, tag) => ({
        ...obj,
        [tag]: this.alwaysAllowedAttributes.concat(
          this.allowedAttributesPerTag[
            tag as keyof typeof this.allowedAttributesPerTag
          ] || []
        ),
      }),
      {}
    );
  },
  allowedIframeHostnames: [
    "www.youtube.com",
    "fast.wistia.net",
    "public.tableau.com",
  ],
  allowedIframeDomains: ["ctfassets.net", "hackerone.com", "tech.flexport.com"],
};

// tracking tags configuration
/**
 * generate google tag tracking script
 * @param {string} containerId
 * @return {string}
 */
const googleTagString = (containerId: string) =>
  `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
  n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));w.fpGTMCustomScriptsNonce=n.nonce||n.getAttribute('nonce');
  f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${containerId || ""}');
  `;

export const TRACKING_CONFIG = {
  GTM_DEFAULT: {
    id: "fp-third-party-gtm",
    script: googleTagString(GTM_CONTAINER_ID!),
    nonce: true,
  },
};
