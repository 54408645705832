import React, {useEffect} from "react";
import invariant from "/src/utils/invariant";
import * as Amplitude from "/src/utils/amplitude";
import {logErrorToSentry} from "/src/utils/sentry";
import {AMPLITUDE_API_KEY, GTM_CONTAINER_ID} from "/src/config";

invariant(
  AMPLITUDE_API_KEY != null,
  "GATSBY_AMPLITUDE_API_KEY must be defined in .env"
);
invariant(
  GTM_CONTAINER_ID != null,
  "GATSBY_GTM_CONTAINER_ID must be defined in .env"
);

function configureAmplitude(): void {
  try {
    Amplitude.init(AMPLITUDE_API_KEY!);
    Amplitude.updateUserProperties({});
  } catch (error) {
    logErrorToSentry(error as Error);
  }
}

function TrackingTags() {
  /* A few notes:
   * Update(2020/10):
   *  Amplitude is blocked by great firewall in China so we turn on other marketing scripts
   *  for CN traffic to track events.
   *
   * Update(2021/07):
   *  We use pardot for tracking.
   *
   * Update:
   *  Our Google Tag Manager manages about 14 marketing tracking js plugins
   *  (Yahoo marketing, Linkedin Insight....) which are async loaded
   *  after Google Tag Manager script initialized. After users opt out tracking,
   *  we have to do a page reload to clean out all js tracking plugins.
   *
   * ================================================================
   * 1. Google Tag Manager technically recommends that you install
   *    a second script tag immediately after the `body` element.
   *    However, as far as I can tell this is only used for users
   *    who have javascript disabled. Since we need javascript to
   *    work in order to be GDPR compliant, we've intentionally
   *    omitted that second script tag.
   *
   * 2. Amplitude has a handy `setOptOut` method that tells amplitude
   *    not to track users when set. This is particularly helpful
   *    because it allows us to keep amplitude in our bundle even
   *    when it is disabled, which means we don't need to do a
   *    bunch of horrible `if (amplitude) {...}` checks everywhere.
   *
   * 3. Though Google's Analytics.js has a similar feature, it appears
   *    that Google Tag Manager does not. Also, Google doesn't provide
   *    an official NPM package - they still like the good old
   *    fashioned "script in the head" method. Therefore, we use
   *    Helmet to do just that.
   *
   * 4. Bizible doesn't appear to support programmatic opt-out.
   *
   * Update (2024/01):
   *  Ketch will be handling all script filtering, so all scripts should load by default.
   *
   * Update (2025/02/11):
   * - Dynamically switch the script type to "text/javascript" when Ketch consent for analytics is given,
   *   ensuring that tracking scripts (like GTM) load correctly on page refresh.
   * - Temporarily use "text/template" instead of "text/plain" to avoid duplicate loads, as ketch.js scans
   *   for that MIME type and conflicts have been observed with the current setup.
   * - This is a temporary solution until we can refactor the tracking logic.
   *
   * Update (2025/03/20):
   * - Moved ~all third-party scripts to GTM
   * - Move GTM script to Head to ensure nonce is always fresh
   * - TODO: setup edge function to create dynamic nonce
   */

  if (!Amplitude.isInitialized()) {
    configureAmplitude();
  }

  // This useEffect sets up an event listener for the 'pardotLoaded' custom event.
  // The GTM snippet that loads the Pardot script dispatches this event once the script has finished loading.
  // When the event fires, we call Amplitude.updateUserProperties({}) to update user properties.
  // This ensures that the site can respond appropriately when Pardot (loaded via GTM) is ready.
  useEffect(() => {
    const handlePardotLoaded = () => {
      Amplitude.updateUserProperties({});
    };

    if (typeof window.getPardotUrl === "function") {
      handlePardotLoaded();
    } else {
      // Otherwise, listen for event dispatched by the GTM script.
      window.addEventListener("pardotLoaded", handlePardotLoaded, {once: true});
      return () => {
        window.removeEventListener("pardotLoaded", handlePardotLoaded);
      };
    }
  }, []);

  return <></>;
}

export default TrackingTags;
